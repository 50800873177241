<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <!-- Page title -->
                <div class="col-lg-12 text-center">
                    <h2 class="display-3 title">Оркестры</h2>
                </div>
                <!-- Page title -->

                <!-- Page content -->
                <div class="col-lg-12">
                    <orchestras-card v-for="item in items" :key="item.ID" :item="item" />
                </div>
                <!-- Page content -->
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios';
//import OrchestrasAccordion from "./components/OrchestrasAccordion"
import OrchestrasCard from "./components/OrchestrasCard";

export default {
    components: {
        OrchestrasCard
    },
    data () {
        return {
            items: ''
        };
    },
    beforeMount() {
        this.get_orchestras();
    },
    methods: {
        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        },
        get_orchestras () {
            this.setHeaders();

            axios.get(this.$apiUrl + "/get_orchestras")
                .then(response => {
                    this.items = response.data;
                });
        }
    }
};
</script>